<template>
  <section class="page-wrap">
    <div class="banner-wrap">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="breadcrumb">
              <ul>
                <li><a href="#" @click.prevent="homePage"><span :class="{'mbg-text-color': mbgGroupCode ==  groupCode || isMbgUser}">Home</span></a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xxl-5 col-xl-5 col-lg-5 col-12 mb-5">
            <div class="caption">
              <div class="caption_container">
                <div class="caption_boxes" v-if="(mbgGroupCode ==  groupCode) || isMbgUser">
                  <h1><br> Let's <strong>Get Started</strong></h1>
                  <div class="links">
                    <ul style="list-style:none;">
                      <li v-if="portalPop" :class="{'mbg-btn-sign-up': mbgGroupCode ==  groupCode || isMbgUser}">
                        <router-link to="#" @click.native="showPop" class="brand-btn" style="background: #1848a0;">Sign Up Today</router-link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="caption_boxes" v-else>
                  <h1><span>Welcome to the Benefit Store </span><br>Let's Get Started...</h1>
                  <div class="links">
                    <ul style="list-style:none;">
                      <li v-if="portalPop">
                        <router-link to="#" @click.native="showPop" class="brand-btn">Start Enrollment</router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <!--platform popup-->
            <div  v-show="platformPopup" class="popups">
              <div class="card-block" >
                <form action="">
                  <div class="col-12 mb-4">
                    <label for="">Choose Enrollment Portal</label>
                    <select name="" class="custom-input" v-model="platformId" id="platformId">
                      <option v-for="item in platforms" :value="item.platform_group_id" v-bind:key="item.platform_group_id">{{item.platform_name}}</option>
                    </select>
                    <p style="color:red;" v-if="platformError['error'] == true">{{platformError['message']}}</p>
                  </div>
                  <div class="enroll-btn agent-btn row">
                    <div class="col-6">
                      <a href="javascript:void(0);" class="dark-btn mb-3" v-on:click="[platformPopup = false, portalPop = true]">Back</a>
                    </div>
                    <div class="col-6">
                      <a href="javascript:void(0);" @click.prevent="choosePortal()"  class="brand-btn  mb-3">Submit</a>
                    </div>

                  </div>
                </form>
              </div>
            </div>
            <div>
            </div>
          </div>
          <div class="col-xxl-7 col-xl-7 col-lg-7 col-12">
            <div class="text-center">
<!--              <img :src="mbgGroupCode ==  groupCode ? mbgImage : bannerImage" alt="banner-image" class="img-fluid">-->
              <img :src="mbgGroupCode ==  groupCode  || isMbgUser  ? mbgBannerImage: bannerImage" alt="banner-image" class="img-fluid">
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>



</template>

<script>
import axios from 'axios';
export default {
  name: "AgentHome",
  data: function () {
    return {
      groupData: [],
      agent_id: '',
      group_id: '',
      visible: false,
      groupErr: [],
      platforms: [],
      platformError: [],
      platformId: '',
      portalPop: true,
      platformPopup: false,
      agent_name: '',
      open_type: '',
      groupCode: '',
      mbgImage: require('../assets/images/mbg-background.png'),
      mbgGroupCode: process.env.VUE_APP_MBG_GROUP_CODE,
      healthPlanGroupCode: process.env.VUE_APP_HEALTH_PLAN_GROUP_CODE,
      landingUrl: '',
      bannerImage: require(process.env.VUE_APP_BANNER_iMAGE),
      mbgBannerImage: require('../assets/images/mbg-new.png'),
      isMbgUser: false
    }
  },
  mounted() {
    let app = this;
    app.agent_id = window.localStorage.getItem('agent_id');
    app.group_id = window.localStorage.getItem('group_id');
    app.open_type = window.localStorage.getItem("open_type");
    app.groupCode = window.localStorage.getItem('group_code');
    app.landingUrl = window.localStorage.getItem('landingUrl');
    app.group_name = app.$store.state.group.gname;
    app.isMbgUser = JSON.parse(window.localStorage.getItem('isMbgUser'));
    app.getGroups();
    app.getPlatforms()
  },
  methods: {
    getGroups: function () {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE + '/get-agent-groups', {
        params: {
          agent_id: app.agent_id,
        }
      })
          .then(function (response) {
            console.log(response.data.data);
            if (response.data.status == 'success') {
              app.groupData = response.data.data;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    checkMbgHealthGroup() {
      let app = this
      const validGroupCodes = [app.mbgGroupCode, app.healthPlanGroupCode];

      if (validGroupCodes.includes(app.groupCode)) {
        return true
      } else {
        return false
      }
    },
    getPlatforms: function () {
      let app = this;
      app.platforms = [];
      axios.get(process.env.VUE_APP_API_BASE + '/get-enrollment-platforms', {
        params: {
          agent_id: app.agent_id,
        }
      })
          .then(function (response) {
            console.log(response.data.data);
            if (response.data.status == 'success') {
              app.platforms = []
              app.platforms = response.data.data;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    choosePortal: function () {
      let app = this;
      console.log('platforms' + app.platformId);
      app.platformError = [];
      if (app.platformId != '') {
        window.localStorage.setItem('portal_id', this.platformId);
        app.platformPopup = false;
        app.visible = true;
        app.nextPage();
      } else {
        app.platformError['error'] = true;
        app.platformError['message'] = "Please choose a platform";
      }
    },
    nextPage: function () {
      this.platformPopup = false;
      this.visible = true;
      this.groupErr = [];
      if (this.group_id == '') {
        this.groupErr['error'] = true;
        this.groupErr['message'] = "Please choose a group";
      } else {
        window.localStorage.setItem('group_id', this.group_id);
        window.localStorage.setItem('enroll_type', 'new-group-member');
        this.$bus.$emit('getMenuList');
        this.$router.push('/home');
      }
    },
    nextPageDefault: function () {
      this.visible = false;
      this.platformPopup = false;
      window.localStorage.setItem('group_id', this.platformId);
      this.$bus.$emit('getMenuList');
      this.$router.push('/home');
    },
    reloadPage: function (pid) {
      this.getPlanDetails();
      this.getPlanPricing();
      this.$router.push({name: 'plan', params: {id: pid}});
    },
    showPop() {
      if (this.checkMbgHealthGroup() || this.isMbgUser) {
        this.$bus.$emit('getMenuList');
        window.localStorage.setItem('portal_id', this.group_id);
        this.nextPage();
      } else {
        this.portalPop = false;
        this.platformPopup = true;
        this.$bus.$emit('getMenuList');
        this.getPlatforms();
      }

    },
    closePop() {
      this.visible = false;
    },
    closePopPlatforms() {
      this.platformPopup = false;
    },
    closeportalPop() {
      this.portalPop = false;
    },
    homePage: function () {
      if (this.open_type !== null && typeof (this.open_type) !== 'undefined') {
        if (this.referral !== null && typeof (this.referral) !== 'undefined') {
          this.$router.push('/referrals?rid=' + this.referral);
        } else {
          if (this.landingUrl) {
            this.$router.push(`/${this.landingUrl}`);
          } else {
            this.$router.push('/');
          }
        }
      } else {
        if (this.eprocess === 'rep') {
          this.$router.push('/agent-home');
        } else if (this.eprocess === 'applicant') {
          this.$router.push('/member-home');
        } else if (this.eprocess === 'group') {
          this.$router.push('/group-home');
        }
      }
    }
  },
  watch: {
    group_id: function () {
      if (this.group_id) {
        this.groupInvaild = false;
      }
    },
    platformId: function () {
      if (this.platformId) {
        this.platformError['error'] = false;
      }
    }
  },
  listener: {
    renderMbguser () {
      let app = this
      app.isMbgUser = Boolean(window.localStorage.getItem('isMbgUser'));
    }
  }
}
</script>

<style scoped>
</style>
