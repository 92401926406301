<template>
  <header>
    <div class="top-header" :class="{'mbg-header-color': mbgGroupCode ==  groupCode }">
      <div class="container">
        <div v-if="(groupCode == mbgGroupCode)">
          <p v-html="tagline"></p>
        </div>
        <div class="row" v-else>
          <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 mobile-hide">
            <ul v-if="agentCode !== mbgAgentCode">
              <li><a :class="{ 'mbg-font': (siteRedirection == 'new-group' && groupCode == mbgGroupCode)}"><i class="fas fa-envelope"></i> {{ email }}</a></li>
              <li><a :class="{ 'mbg-font': (siteRedirection == 'new-group' && groupCode == mbgGroupCode)}"><i class="fas fa-phone-alt" ></i> {{ phone }}</a></li>
            </ul>
          </div>
          <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12" v-if="!(siteRedirection == 'new-group' && groupCode == mbgGroupCode)">
            <p v-html="tagline"></p>
          </div>
        </div>
      </div>
    </div>
    <div class="main-header">
      <div class="container">
        <div class="row" v-if="isMbgUser">
          <div class="col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-sm-6 col-6"  v-if="mbgLogo">
            <div class="mbg-image">
              <img :src="mbgLogo" alt="Image">
            </div>
          </div>
        </div>
        <div class="row" v-else>
          <div class="col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-sm-6 col-6" v-if="logo">
            <div class="store-logo" :class="{'mbg-image': groupCode == mbgGroupCode}"  @click="siteRedirection !== 'new-group' ? homePage(): ''">
              <img :src="logo" alt="Image"  class= "img-fluid" >
            </div>
          </div>
          <div class="col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-sm-6 col-6 mobile-hide"
               :class="{'left-border': logo && image}" v-if="image">
            <div class="agent-logo" v-if="groupCode !== mbgGroupCode">
              <img :src="image" alt="Image" class="img-fluid" >
            </div>
          </div>
          <div class="col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-sm-6 col-6" v-if="!logo && !image">
            <img :src="enrollibleLogo" alt="Image"  class= "img-fluid">
          </div>
          <div class="col-xxl-8 col-xl-8 col-lg-6 col-md-6 col-6 vertical-center">
            <div class="cart-btn">
                            <span v-if="siteRedirection == 'new-group'">
                              <img :src="cartLogo" alt="">
                            </span>
              <router-link to="/cart" v-b-tooltip.hover title="View Cart" v-else>
                <img :src="cartLogo" alt="">
              </router-link>
              <span class="badge">{{ cartItems }}</span>
            </div>
            <div class="about-btn mobile-hide" v-if="(groupCode !== mbgGroupCode && agentCode !== mbgAgentCode)">
              <router-link to="" v-b-modal.aboutUser>About {{ display_name }}</router-link>
            </div>
            <!--                        <div class="headerApp-list">
                                    <b-dropdown class="dashboard-icon"  v-if="siteRedirection !== 'new-group'">
                                        <template #button-content>
                                            <img src="../assets/images/enrollibleImage/icons/app-icon.svg" id="dash-icon" />
                                        </template>
                                        <template #item-content>

                                        </template>
                                        <b-dropdown-item href="javascript:void(0)">
                                            <div class="row">
                                                <a :href="menuLink.routeToAdmin" class="col-4 dashIcon-block" target="_blank">
                                                    <img src="../assets/images/enrollibleImage/icons/admin.svg" alt="" />
                                                    <span class="d-block text-center">Admin</span>
                                                </a>
                                                <a :href="menuLink.routeToMessageCenter" class="col-4 dashIcon-block" target="_blank">
                                                    <img src="../assets/images/enrollibleImage/icons/message-center.svg" alt="" />
                                                    <span class="d-block text-center">Message center</span>
                                                </a>
                                                <a :href="menuLink.routeToFileManagement" class="col-4 dashIcon-block" target="_blank">
                                                    <img src="../assets/images/enrollibleImage/icons/file-management.svg" alt="" />
                                                    <span class="d-block text-center">File Management</span>
                                                </a>
                                                <a :href="menuLink.routeToRepDashboard" class="col-4 dashIcon-block" target="_blank">
                                                    <img src="../assets/images/enrollibleImage/icons/Dashboard-REP.svg" alt="" />
                                                    <span class="d-block text-center">Dashboard (Rep)</span>
                                                </a>
                                                <a :href="menuLink.routeToMemberDashboard" class="col-4 dashIcon-block" target="_blank">
                                                    <img src="../assets/images/enrollibleImage/icons/Dashboard-Member.svg" alt="" />
                                                    <span class="d-block text-center">Dashboard (Member)</span>
                                                </a>
                                                <a :href="menuLink.routeToGroupDashboard" class="col-4 dashIcon-block" target="_blank">
                                                    <img src="../assets/images/enrollibleImage/icons/Dashboard-group.svg" alt="" />
                                                    <span class="d-block text-center">Dashboard (Group)</span>
                                                </a>
                                                <a :href="menuLink.routeToTicketManagement" class="col-4 dashIcon-block" target="_blank">
                                                    <img src="../assets/images/enrollibleImage/icons/ticket-management.svg" alt="" />
                                                    <span class="d-block text-center">Ticket <br>Management</span>
                                                </a>
                                                <a  :href="menuLink.routeToSos" class="col-4 dashIcon-block" target="_blank">
                                                    <img src="../assets/images/enrollibleImage/icons/SSO.svg" alt="" />
                                                    <span class="d-block text-center">SSO</span>
                                                </a>
                                                <hr>
                                                <h3>Registrations</h3>
                                                <a  @click="routeToLink(menuLink.routeToRepRegistration, 'Rep Registration')" class="col-4 dashIcon-block" target="_blank">
                                                    <img src="../assets/images/enrollibleImage/icons/Da-Reg.svg" alt="" />
                                                    <span class="d-block text-center">Rep<br>Registration</span>
                                                </a>
                                                <a  :href="menuLink.routeToMemberRegistration" class="col-4 dashIcon-block" target="_blank">
                                                    <img src="../assets/images/enrollibleImage/icons/Dm-Reg.svg" alt="" />
                                                    <span class="d-block text-center">Member<br>Registration</span>
                                                </a>
                                                <a   @click="routeToLink(menuLink.routeToGroupRegistration, 'Group Registration')" class="col-4 dashIcon-block" target="_blank">
                                                    <img src="../assets/images/enrollibleImage/icons/Dg-Reg.svg" alt="" />
                                                    <span class="d-block text-center">Group<br>Registration</span>
                                                </a>

                                            </div>
                                        </b-dropdown-item>
                                    </b-dropdown>

                                </div>-->

          </div>
        </div>
      </div>
    </div>
    <!-- MBG Naviation bar      -->
    <div v-if="groupCode == mbgGroupCode && siteRedirection !== 'new-group'">
      <!--          <div class="container">
                  <ul>
                    <li><a :href="mbg.home" class="menu" target="_blank">HOME</a></li>
                    <li><a :href="mbg.membershipUrl">MEMBERSHIPS</a></li>
                    <li><a :href="mbg.faq" target="_blank">FAQS</a></li>
                    <li><a :href="mbg.testimonials" target="_blank">TESTIMONIALS</a></li>
                  </ul>
                </div>-->
    </div>
    <div v-else>
      <div class="nav-menu mobile-hide" v-if="portal_id && siteRedirection !== 'new-group'">
        <div class="container">
          <ul>

            <li v-for="(menu,index) in menuArray" v-bind:key="index">
              <a href="javascript:void(0)"
                 class="menu"
                 :class="{'active-header': menu === menuSelectedItem}"
                 @click="menuItem(menu)">{{ menu }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>


    <div class="vertical-line" v-if="!portal_id"> </div>
    <!-- User About Pop up -->
    <b-modal id="aboutUser" centered size="md"  v-model="show">
      <template #modal-header>
        <div class="popUp-header">
          <h4>About {{ display_name }}</h4>

          <b-button variant="close-btn" @click="show=false">
            <i class="fas fa-times"></i>
          </b-button>
        </div>
      </template>
      <b-container fluid>
        <div class="aboutUser-details">
          <p v-html="about_content"></p>
        </div>
      </b-container>
    </b-modal>
  </header>
</template>
<script>
import axios from "axios";
export default {
  name: 'SiteHeader',
  data() {
    return {
      show: false,
      logo: '',
      image: '',
      cartLogo: require(process.env.VUE_APP_CART_LOGO),
      phone: 'Loading..',
      email: 'Loading..',
      tagline: '',
      about_name: '...',
      display_name: '...',
      cartItems: 0,
      about_popup: false,
      about_content: 'Not Available',
      eprocess: '',
      open_type: '',
      group_id: '',
      agent_id: '',
      menuList: [],
      /* menus: {
        dental: '',
        vision: '',
        medical: '',
        limitedmed: '',
        term_life: '',
        supplemental: '',
        lifestyle: '',
        rx: '',
        di: '',
        critical: '',
        accident: '',
        hospital: ''
      },*/
      portal_id: '',
      menus: {},
      menuName: '',
      menuArray:[],
      isActive: false,
      menuSelectedItem: '',
      registrationArray: [
        {
          name: 'Rep Registration',
          link: process.env.VUE_APP_REP_REGISTRATION
        },
        {
          name: 'Rep Dashboard',
          link: process.env.VUE_APP_REP_DASHBOARD
        },
        {
          name: 'Group Registration',
          link:  process.env.VUE_APP_GROUP_REGISTRATION
        },
        {
          name: 'Group Dashboard',
          link: process.env.VUE_APP_GROUP_DASHBOARD
        },
        {
          name: 'Member Registration',
          link: process.env.VUE_APP_MEMBER_REGISTRATION
        },
        {
          name: 'Member Dashboard',
          link: process.env.VUE_APP_MEMBER_DASHBOARD
        }
      ],
      menuLink: {
        routeToAdmin: process.env.VUE_APP_ADMIN,
        routeToMessageCenter: process.env.VUE_APP_MESSAGE_CENTER,
        routeToFileManagement: process.env.VUE_APP_FILE_MANAGEMENT,
        routeToRepDashboard: process.env.VUE_APP_REP_DASHBOARD,
        routeToMemberDashboard: process.env.VUE_APP_MEMBER_DASHBOARD,
        routeToGroupDashboard: process.env.VUE_APP_GROUP_DASHBOARD,
        routeToTicketManagement: process.env.VUE_APP_TICKET_MANAGEMENT,
        routeToSos: process.env.VUE_APP_SOS,
        routeToRepRegistration: process.env.VUE_APP_REP_REGISTRATION,
        routeToMemberRegistration: process.env.VUE_APP_MEMEBER_REGISTRATION,
        routeToGroupRegistration: process.env.VUE_APP_GROUP_REGISTRATION,
      },
      agentCode: null,
      siteRedirection: null,
      gname: '',
      groupCode: '',
      mbgGroupCode: process.env.VUE_APP_MBG_GROUP_CODE,
      mbgAgentCode: process.env.VUE_APP_MBG_AGENT_CODE,
      mbg: {
        home: process.env.VUE_APP_MBG_HOME,
        membershipUrl: `${process.env.VUE_APP_MEMBERSHIP_URL}/plans?type=lifestyle`,
        faq: process.env.VUE_APP_MBG_FAQS,
        testimonials: process.env.VUE_APP_MBG_TESTIMONIALS
      },
      isMbgRep: false,
      landingUrl: '',
      mbgLogo: require('../assets/images/mbg-new-logo.jpg'),
      isMbgUser: false,
      enrollibleLogo: 'https://reps.enrollible.com/img/enrollible-logo.5d4d99c0.png',
    }
  },
  mounted() {
    let app = this;
    app.group_id = window.localStorage.getItem('group_id');
    app.agent_id = window.localStorage.getItem('agent_id');
    app.open_type = window.localStorage.getItem('open_type');
    app.eprocess = window.localStorage.getItem('eprocess');
    app.portal_id = window.localStorage.getItem('portal_id');
    app.referral = window.localStorage.getItem('referral');
    app.siteRedirection = window.localStorage.getItem('siteRedirection');
    app.gname = window.localStorage.getItem('gname');
    app.groupCode = window.localStorage.getItem('group_code');
    app.agentCode = window.localStorage.getItem('agent_code');
    app.landingUrl = window.localStorage.getItem('landingUrl');
    app.isMbgUser = JSON.parse(window.localStorage.getItem('isMbgUser'));
    app.cartItems = localStorage.getItem('cartCount') ? localStorage.getItem('cartCount') : app.cartItems;
    localStorage.getItem('menuSelectedItem') ? app.menuSelectedItem = localStorage.getItem('menuSelectedItem'): '';
    console.log('eprocess of landing page',app.eprocess);
    app.getMenus();
    app.getAgentCode();
    app.checkMbgRepDownline();
    if (app.open_type === 'group' || app.eprocess === 'group') {
      app.getGroupHeaderInfo();
      app.getGroupAboutInfo();
    } else {
      //open landing pages
      app.getAgentHeaderInfo();
      app.getAgentAboutInfo();
    }
    app.$root.$on('cartData', (id) => {
      app.cartCount(id);
    });
    app.$root.$on('cartCountStatus', (id) => {
      console.log('***** inside cart count status*****')
      console.log(id)
      app.cartItems = localStorage.getItem('cartCount') ?  localStorage.getItem('cartCount') : 0
    });
    this.$root.$on('menuSelected', (menu) => {
      console.log(menu)
      app.menuSelectedItem = ''
    });
  },
  methods: {
    homePage: function() {
      if (this.open_type !== null && typeof(this.open_type) !== 'undefined') {
        if (this.referral !== null && typeof(this.referral) !== 'undefined') {
          this.$router.push('/referrals?rid='+this.referral);
        } else {
          if (this.landingUrl) {
            this.$router.push(`/${this.landingUrl}`);
          } else {
            this.$router.push('/');
          }
        }
      } else {
        if (this.eprocess === 'rep') {
          this.$router.push('/agent-home');
        }else if (this.eprocess === 'applicant') {
          this.$router.push('/member-home');
        } else if (this.eprocess === 'group') {
          this.$router.push('/group-home');
        }
      }
    },
    getMenus: function() {
      console.log('******* getMenus *********')
      let app = this;
      let url;
      // delete app.menus.di;
      let portal = window.localStorage.getItem('portal_id');
      app.group_id = window.localStorage.getItem('group_id');
      // let group = window.localStorage.getItem('group_id');
      if(app.eprocess === 'rep')
      {
        if (portal !== null) {
          url = process.env.VUE_APP_API_BASE+`/get-common-menu?agent_id=${app.agent_id}&group_id=${app.group_id}&portal_id=${portal}&eprocess=${app.eprocess}&open_type=${app.open_type}`;
        } else {
          url = process.env.VUE_APP_API_BASE+`/get-common-menu?agent_id=${app.agent_id}&group_id=${app.group_id}&eprocess=${app.eprocess}&open_type=${app.open_type}`;
        }
        // url = process.env.VUE_APP_API_BASE+`/get-agent-menu?agent_id=${app.agent_id}`;
      } else {
        let fromSite = window.localStorage.getItem('fromSite')
        if ( fromSite == 'elevate') {
          url = process.env.VUE_APP_API_BASE+`/get-group-menu?group_id=${app.group_id}&from_site=${fromSite}`;
        } else {
          if (portal !== null) {
            url = process.env.VUE_APP_API_BASE+`/get-common-menu?agent_id=${app.agent_id}&group_id=${app.group_id}&portal_id=${portal}&eprocess=${app.eprocess}&open_type=${app.open_type}`;
          } else {
            url = process.env.VUE_APP_API_BASE+`/get-common-menu?agent_id=${app.agent_id}&group_id=${app.group_id}&eprocess=${app.eprocess}&open_type=${app.open_type}`;
          }
          // url = process.env.VUE_APP_API_BASE+`/get-group-menu?group_id=${app.group_id}`;
        }
      }
      axios.get(url).then(function(response)
      {
        if (response.data.status === 'success') {
          console.log('****** inside getMenus success ******')
          response.data.data.forEach( (item) => {
            console.log('****** inside getMenus for loop ******')
            app.menuArray.push(item);
            if (item == 'dental') {
              console.log('****** inside getMenus for loop  dental ******', item)
              app.menus.dental = 'Dental';
            }
            if (item == 'vision') {
              app.menus.vision = 'Vision';
            }
            if (item == 'medical') {
              app.menus.medical = 'Medical';
            }
            if (item == 'limitedmed') {
              app.menus.limitedmed = 'Limited Med';
            }
            if (item == 'term_life') {
              app.menus.term_life = 'Term Life';
            }
            if (item == 'supplemental') {
              app.menus.supplemental = 'Supplemental';
            }
            if (item == 'lifestyle') {
              app.menus.lifestyle = 'Lifestyle';
            }
            if (item == 'rx') {
              app.menus.rx = 'RX';
            }
            if (item == 'di') {
              app.menus.di = 'Disability Income';
            }
            if (item == 'critical') {
              app.menus.critical = 'Critical Illness'
            }
            if (item == 'accident') {
              app.menus.accident = 'Accident';
            }
            if (item == 'hospital') {
              app.menus.hospital = 'Hospital';
            }
            if (item == 'pet') {
              app.menus.pet = 'PET';
            }
          });
          app.menuList.push(app.menus)
        }
      })
          .catch(function (error) {
            console.log(error);
          });
    },
    menuItem: function(val) {
      let app = this
      /****** Please don`t delete this code it may be used later
       /*  if (val === 'Dental') {
       app.menuName = 'dental';
       }
       if (val === 'Vision') {
       app.menuName ='vision';
       }
       if (val === 'Medical') {
       app.menuName = 'medical';
       }
       if (val === 'Limited Med') {
       app.menuName = 'limitedmed';
       }
       if (val === 'Term Life') {
       app.menuName = 'term_life';
       }
       if (val === 'Supplemental') {
       app.menuName = 'supplemental';
       }
       if (val === 'Lifestyle') {
       app.menuName = 'lifestyle';
       }
       if (val === 'RX') {
       app.menuName = 'rx';
       }
       if (val === 'Disability Income') {
       app.menuName = 'di';
       }
       if (val === 'Critical Illness') {
       app.menuName  = 'critical'
       }
       if (val === 'Accident') {
       app.menuName  = 'accident';
       }
       if (val === 'Hospital') {
       app.menuName  = 'hospital';
       }
       if (val === 'PET') {
       app.menuName  = 'pet';
       } *************/
      if (val === 'dental') {
        app.menuName = 'dental';
      }
      if (val === 'vision') {
        app.menuName ='vision';
      }
      if (val === 'medical') {
        app.menuName = 'medical';
      }
      if (val === 'limitedmed') {
        app.menuName = 'limitedmed';
      }
      if (val === 'term_life') {
        app.menuName = 'term_life';
      }
      if (val === 'supplemental') {
        app.menuName = 'supplemental';
      }
      if (val === 'lifestyle') {
        app.menuName = 'lifestyle';
      }
      if (val === 'rx') {
        app.menuName = 'rx';
      }
      if (val === 'di') {
        app.menuName = 'di';
      }
      if (val === 'critical') {
        app.menuName  = 'critical'
      }
      if (val === 'accident') {
        app.menuName  = 'accident';
      }
      if (val === 'hospital') {
        app.menuName  = 'hospital';
      }
      if (val === 'pet') {
        app.menuName  = 'pet';
      }
      app.menuSelectedItem = val;
      localStorage.setItem('menuSelectedItem', val)
      this.$root.$emit('menuData', app.menuName);
      this.$router.push({name: 'plans', query: {type: app.menuName}})
    },
    logout: function() {
      localStorage.clear();
      this.$router.push('/');
    },
    cartPage: function () {
      this.$router.push({name: 'cart'});
    },
    cartCount: function (id) {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE + '/get-cart-count', {
        params: {
          enrollment_id: id,
        }
      })
          .then(function (response) {
            if (response.data.status == 'success') {
              app.cartItems = response.data.data.result;
              localStorage.setItem('cartCount', app.cartItems)
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    getAgentHeaderInfo: function () {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE + '/get-agent-landing-page-info', {
        params: {
          agent_id: app.agent_id
        },
      })
          .then(function (response) {
            if (response.data.data.logo !== null) {
              app.logo = response.data.data.logo;
            }
            app.phone = response.data.data.display_phone;
            app.email = response.data.data.display_email;
            app.tagline = response.data.data.tagline;
            app.about_name = response.data.data.display_about_name;
            app.display_name = response.data.data.display_about_name;
            if (response.data.data.image !== null) {
              app.image = response.data.data.image;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    getAgentAboutInfo: function () {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE + '/get-agent-about-page-info', {
        params: {
          agent_id: app.agent_id
        },
      })
          .then(function (response) {
            app.about_content = response.data.data.agent_bio;
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    getGroupHeaderInfo: function () {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE + '/get-group-landing-page-info', {
        params: {
          group_id: app.group_id
        },
      })
          .then(function (response) {
            if (response.data.data.logo !== null) {
              app.logo = response.data.data.logo;
            }
            app.phone = response.data.data.display_phone;
            app.email = response.data.data.display_email;
            app.tagline = response.data.data.tagline;
            app.about_name = response.data.data.display_about_name;
            app.display_name = response.data.data.display_about_name;
            app.gname = response.data.data.group_name;
            if (response.data.data.image !== null) {
              app.image = response.data.data.image;
            }
            localStorage.setItem('gname', response.data.data.group_name)
            app.$bus.$emit('getFooterGroupCode')
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    getGroupAboutInfo: function () {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE + '/get-group-about-page-info', {
        params: {
          group_id: app.group_id
        },
      })
          .then(function (response) {
            app.about_content = response.data.data.gweb_aboutus;
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    getAgentCode () {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE + '/get-agent-code', {
        params: {
          agent_id: app.agent_id
        },
      })
          .then(function (response) {
            app.agentCode = response.data.data.code
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    routeToLink (value, name) {
      let app = this
      if (name == 'Rep Registration' || name == 'Group Registration') {
        window.open(`${value}?repCode=${app.agentCode}`, '_blank').focus()
      } else {
        window.open(value, '_blank').focus();
      }
    },
    checkMbgRepDownline () {
      let app = this;
      if (app.eprocess == 'rep') {
        axios.get(process.env.VUE_APP_API_BASE + '/new/check-mbg-rep', {
          params: {
            agent_id: app.agent_id
          },
        })
            .then(function (response) {
              app.isMbgRep = response.data.data.is_mbg_rep
            })
            .catch(function (error) {
              console.log(error);
            });
      }
    },
  },
  // Event bus library build in method listener object
  listener: {
    getMenuList () {
      this.menuArray = []
      this.getMenus()
      this.portal_id = window.localStorage.getItem('portal_id');
    },
    FromDirect (){
      let app = this;
      app.group_id = window.localStorage.getItem('group_id');
      app.portal_id = window.localStorage.getItem('portal_id');
      app.agent_id = window.localStorage.getItem('agent_id');
      app.getMenus();

      if (app.open_type === 'group' || app.eprocess === 'group') {
        app.getGroupHeaderInfo();
        app.getGroupAboutInfo();
      } else {
        app.getAgentHeaderInfo();
        app.getAgentAboutInfo();
      }
    },
    getGroupCode () {
      let app = this
      app.groupCode = window.localStorage.getItem('group_code');
    },
    renderMbguser () {
      let app = this
      app.isMbgUser = Boolean(window.localStorage.getItem('isMbgUser'));
    }
  }
}
</script>

<style>


</style>
