<template>
  <div class="main_wrapper product_list cart_page agent_landing plan_details informations" v-if="this.$router.name=='error'">
    <error-page></error-page>
  </div>
  <div class="main_wrapper product_list cart_page agent_landing plan_details informations" v-else>
    <site-header v-if="include_extra"></site-header>
    <router-view></router-view>
    <enrollible-home v-if="isOpenCommonEnrollment && siteEnrollible"></enrollible-home>
<!--    <open-page v-if="landing_type == 'user' && !isOpenEnrollment"></open-page>-->
    <agent-open-page v-else-if="landing_type == 'agent' && isOpenEnrollment"></agent-open-page>
    <group-open-page v-else-if="landing_type == 'group' && isOpenEnrollment"></group-open-page>
    <site-footer v-if="include_extra"></site-footer>
  </div>
</template>

<script>
  import ErrorPage from '../components/404'
  // import OpenPage from "../views/OpenPage"
  import AgentOpenHome from "../views/AgentOpenHome";
  import GroupOpenHome from "../views/GroupOpenHome";
  import EnrollibleHome from "./EnrollibleHome";
  import SiteHeader from "../components/SiteHeader";
  import SiteFooter from '../components/SiteFooter';
  import axios from "axios";
  export default {
    name: "Home",
    components: {
      // 'open-page' : OpenPage,
      'agent-open-page' : AgentOpenHome,
      'group-open-page' : GroupOpenHome,
      'error-page' : ErrorPage,
      'enrollible-home': EnrollibleHome,
      'site-header': SiteHeader,
      'site-footer': SiteFooter,
    },
    data: function() {
      return {
        landing_type : '',
        include_extra: true,
        include_old: false,
        siteRedirection: null,
        planId: null,
        eprocess: null,
        openType: null,
        agentCode: null,
        groupCode: null
      }
    },
    computed: {
      isOpenEnrollment() {
        if (this.$route.name == "main") {
          return true;
        } else {
          return false
        }
      },
      isOpenCommonEnrollment() {
        if (this.$route.name == "main") {
          if (this.$route.params.landing) {
            return false;
          } else {
            return true
          }
        } else {
          return false
        }
      },
      siteEnrollible () {
        if (process.env.VUE_APP_SITE_NAME === 'enrollible') {
          return true
        } else {
          return false
        }
      }
    },
    mounted() {
      console.log('parent called');
      let app = this;
      app.landing_type = window.localStorage.getItem('open_type');
      app.include_extra = app.$route.name !== 'error' && app.$route.name !== 'continue-enrollment' && !app.isOpenCommonEnrollment;
      app.include_old = app.$route.name === 'continue-enrollment';
      app.siteRedirection = window.localStorage.getItem('siteRedirection');
      app.planId = window.localStorage.getItem('planId');
      app.eprocess = window.localStorage.getItem('eprocess');
      app.openType = window.localStorage.getItem('open_type');
      app.agentCode = window.localStorage.getItem('agent_code');
      app.groupCode = window.localStorage.getItem('group_code');
      app.checkForMbgdownline();
      app.checkUsagRedirectionFlow();
      app.routeToOptionPlus();
    },
    methods: {
      checkForMbgdownline() {
        let app = this
        let params = {};
        if (app.eprocess == 'rep') {
          params['user_type'] = 'A'
          params['user_code'] = app.agentCode
        }

        if (app.eprocess == 'applicant' && app.openType == 'agent') {
          params['user_type'] = 'A'
          params['user_code'] = app.agentCode
        }

        if (app.eprocess == 'group') {
          params['user_type'] = 'G'
          params['user_code'] = app.groupCode
        }

        if (app.eprocess == 'applicant' && app.openType == 'group') {
          params['user_type'] = 'G'
          params['user_code'] = app.groupCode
        }
        axios.get(process.env.VUE_APP_API_BASE + '/check-mbg-user', {
          params: params
        })
          .then(function (response) {
            if (response.data.status == 'success') {
              console.log(response.data.data.is_mbg_user)
              let isMbgdownline = response.data.data.is_mbg_user
              if (isMbgdownline) {
                window.localStorage.setItem('group_code', process.env.VUE_APP_MBG_GROUP_CODE);
                window.localStorage.setItem('isMbgUser', isMbgdownline);
                app.$bus.$emit('getGroupCode');
                app.$bus.$emit('checkColor')
                app.$bus.$emit('renderMbguser')
              } else {
                window.localStorage.setItem('isMbgUser', isMbgdownline);
              }
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      },
      routeToOptionPlus () {
        if (this.$route.name == "main") {
          console.log('***** inside option plus site ****')
          if (this.$route.params.landing) {
            return false;
          } else {
            if (process.env.VUE_APP_SITE_NAME === 'optionPlus') {
              location.replace(process.env.VUE_APP_REDIRECT_OPT);
            }
            return true
          }
        } else {
          return false
        }
      },
      checkUsagRedirectionFlow () {
        // Methods main purpose is to check usag site and restrict some route for usag and redirect to cart page.
        let app = this
        if (app.siteRedirection == 'new-group') {
          if (app.$route.name == 'plans ' || app.$route.path == '/home' || app.$route.path == '/agent-home' ||
              app.$route.path == '/plan-coverage' || app.$route.path == '/agent-home-direct' || app.$route.path == '/website' || app.$route.path == '/agent-select-member' ||
              app.$route.path == '/wishlist' || app.$route.path == '/plans' || app.$route.path == '/group-home' || app.$route.path == '/group-select-member' ||
              app.$route.path == '/member-home' || app.$route.path == '/BenAdvanceHome' || app.$route.path == '/referrals' || app.$route.path == '/external' ||
              app.$route.path == '/recommend-list' || app.$route.path == '/404') {
            app.$router.push(`/plan/${app.planId}/new-group`)
          }
        }
      }
    }
  }
</script>
