<template>
    <div class="enrollibleLanding">
        <div class="siteheader">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-5 col-sm-6 col-6">
                        <div class="logo">
                            <img src="../assets/images/enrollibleImage/logo.svg" class="img-fluid" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End of header -->

        <section class="banner-wrap" data-aos="fade-up">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="banner-text text-center">
                            <h1>One Stop Solutions for All your <br> Business Needs</h1>
                            <p>From developing to maintaining and gaining business momentum, <br> Enrollible will be with you throughout the whole process. We are your solution provider for all business problems.</p>
                            <div class="get-started">
                                <a href="#contact">Get Started</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 pt-5">
                        <carousel-3d :autoplay="true" :display="3" height="370" width="700" autoplayTimeout="3000" animationSpeed="1500" border="0">
                            <slide :index="0">
                            <img src="../assets/images/enrollibleImage/b1.png"  class="img-fluid" alt="lorem ipsum dolor sit"/>
                            </slide>
                            <slide :index="1">
                            <img src="../assets/images/enrollibleImage/b2.png"  class="img-fluid" alt="lorem ipsum dolor sit"/>
                            </slide>
                            <slide :index="2">
                            <img src="../assets/images/enrollibleImage/b3.jpg" d class="img-fluid" alt="lorem ipsum dolor sit"/>
                            </slide>
                        </carousel-3d>
                    </div>
                </div>
            </div>
        </section>
        <!-- End of banner -->

        <section class="about-wrap">
            <div class="container">
                <div class="row justify-content-between">
                    <div class="col-md-6">
                        <div class="about-text-block text-start" data-aos="fade-up" data-aos-duration="1000">
                            <span class="tagline">Our Story Our Strangth</span>
                            <h2><span class="brand-color">About</span> - What We Believe</h2>
                            <p>
                                We believe in providing high quality experience to our customers. Enrollible is committed to making sure that each and every service we provide is of the best level.
                             <br><br>   Our team of highly skilled and experienced individuals make sure that your projects will give you the best results. Furthermore, it is our belief that we are here to make your business effective and efficient.

                            </p>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="about-img" data-aos="fade-up" data-aos-duration="1500">
                            <img src="../assets/images/enrollibleImage/br_pic.png" class="img-fluid" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End of about -->

        <section class="features">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-7">
                        <div class="section-title text-center mb-4">
                            <h2><span class="brand-color">Features</span> - What We Offer</h2>
                            <p>Here are some of our unique and quirky features that will help your business become the best in the industry.</p>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 mt-5">
                        <div class="feature-grid text-center" data-aos="fade-up" data-aos-duration="1000">
                            <img src="../assets/images/enrollibleImage/icons/ec.svg" alt="">
                            <h3>Enrollment Center</h3>
                            <p>Secure all your information in the cloud and go paperless. Enrollment Center provides a much easier way to enroll members into organization or a benefit plan, without clutter.
                                </p>
                        </div>
                    </div>
                    <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 mt-5">
                        <div class="feature-grid text-center" data-aos="fade-up" data-aos-duration="1200">
                            <img src="../assets/images/enrollibleImage/icons/pbs.svg" alt="" >
                            <h3>Billing Solutions</h3>
                            <p>With more than 20,000 invoices handled by our billing solutions every month, rest assured because you are in for a smooth ride with Enrollible. Our automated invoice generation and updation processes makes managing invoices hassle free. </p>
                        </div>
                    </div>
                    <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 mt-5">
                        <div class="feature-grid text-center" data-aos="fade-up" data-aos-duration="1400">
                            <img src="../assets/images/enrollibleImage/icons/eft.svg" alt="">
                            <h3>Electronic File Transfer</h3>
                            <p>Our end-to-end encrypted file transfer lets you send and receive high volume files that may contain sensitive information, securely. Additionally the 2FA for any downloads makes our system best in the industry for secured file transfer.</p>
                        </div>
                    </div>
                    <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 mt-5">
                        <div class="feature-grid text-center" data-aos="fade-up" data-aos-duration="1600">
                            <img src="../assets/images/enrollibleImage/icons/tcm.svg" alt="">
                            <h3>Ticketing Module</h3>
                            <p>Our ticketing system is sure to make each one of our customers feel like VIP. This module enables us to take care of customer issues more efficiently and on pre-defined SLA. </p>
                        </div>
                    </div>
                    <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 mt-5">
                        <div class="feature-grid text-center" data-aos="fade-up" data-aos-duration="1800">
                            <img src="../assets/images/enrollibleImage/icons/cc.svg" alt="">
                            <h3>Communication Center</h3>
                            <p>By providing you an easier way to communicate with your customers via Email, Text Messages and Push Notifications, Enrollible has found a solution for you to effectively and efficiently liaise with your customers and vice versa.</p>
                        </div>
                    </div>
                    <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 mt-5">
                        <!-- <div class="feature-grid text-center" data-aos="fade-up" data-aos-duration="2000">
                            <img src="../assets/images/enrollibleImage/icons/ems.svg" alt="">
                            <h3>Education Management Software</h3>
                            <p>The software collects, integrates as well as processes the data while maintaining and disseminating the data and information. This provides an easier option for schools to monitor and manage the school and also plan for its betterment if necessary.</p>
                        </div> -->
                    </div>
                </div>
            </div>
        </section>
        <!-- End of features -->

        <section class="products-wrap">
            <div class="container">
                <div class="row justify-content-between align-items-center mb-5">
                    <div class="col-md-5">
                        <div class="product-img" data-aos="fade-up" data-aos-duration="1000">
                            <img src="../assets/images/enrollibleImage/rep-dashboard.png" class="img-fluid" alt="">
                            <div class="p-high">
                                <img src="../assets/images/enrollibleImage/rep-high.png" class="img-fluid" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="product-detail text-start" data-aos="fade-up" data-aos-easing="linear">
                            <span class="tagline brand-color">Enrollment</span>
                            <h2>Rep Dashboard</h2>
                            <p>This dashboard has an even broader perspective, that is so, because you have access to the information regarding the payments, invoices of everyone in your organization.
                               <br><br> The Rep dashboard is especially focused towards the higher level personnels in an organization as you can also update selective information regarding the members of your organization.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-between align-items-center mb-5">
                    <div class="col-md-6">
                        <div class="product-detail text-start" data-aos="fade-up" data-aos-easing="linear">
                            <span class="tagline brand-color">Communication Center</span>
                            <h2>Message Center</h2>
                            <p>You have access to an easier way to communicate with your clients via Email, Text Messages and Push Notifications. Enrollible has a solution for you to efficiently and effectively liaise with your clients and the other way around.
                            <br><br>
                            Moreover, the Message Center has a pre-configurable module that enables it to support any commission structure. This means it also handles and controls communication traffic systematically, making communication even easier.
                            </p>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="product-img left-img" data-aos="fade-up" data-aos-duration="1000">
                            <img src="../assets/images/enrollibleImage/message-center.png" class="img-fluid" alt="">
                            <div class="p-high">
                                <img src="../assets/images/enrollibleImage/msg-high.png" class="img-fluid" alt="">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-between align-items-center mb-5">
                    <div class="col-md-5">
                        <div class="product-img" data-aos="fade-up" data-aos-duration="1000">
                            <img src="../assets/images/enrollibleImage/member-dashboard.png" class="img-fluid" alt="">
                            <div class="p-high">
                                <img src="../assets/images/enrollibleImage/member-high.png" class="img-fluid" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="product-detail text-start" data-aos="fade-up" data-aos-easing="linear">
                            <span class="tagline brand-color">Enrollment</span>
                            <h2>Member Dashboard</h2>
                            <p>
                                Get all the necessary information about yourself in a single page. It is an information-made-easy page where you can view all your details including the invoices and payments.
                             <br><br>   Just a look at this dashboard and you will be able to sort out as well as update these details.

                            </p>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-between align-items-center mb-5">
                    <div class="col-md-6">
                        <div class="product-detail text-start" data-aos="fade-up" data-aos-easing="linear">
                            <span class="tagline brand-color">Enrollment</span>
                            <h2>Benefit Store</h2>
                            <p>
                                Work smarter, not harder with the Benefit Store service.

                                It is an enrollment service that will save you  time and trees by going paperless! It’s an easy way to enroll members into a benefit plan or organization of your choice without clutter.
                                <br><br>
                                Furthermore, all important information is securely stored in “the cloud.”

                            </p>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="product-img left-img" data-aos="fade-up" data-aos-duration="1000">
                            <img src="../assets/images/enrollibleImage/benefit-store.png" class="img-fluid" alt="">

                        </div>
                    </div>
                </div>
                <div class="row justify-content-between align-items-center mb-5">
                    <div class="col-md-5">
                        <div class="product-img" data-aos="fade-up" data-aos-duration="1000">
                            <img src="../assets/images/enrollibleImage/payment-system.png" class="img-fluid" alt="">
                            <div class="p-high">
                                <img src="../assets/images/enrollibleImage/payment-high.png" class="img-fluid" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="product-detail text-start" data-aos="fade-up" data-aos-easing="linear">
                            <span class="tagline brand-color">Billing Solutions</span>
                            <h2>Payment</h2>
                            <p>
                                Enrollible keeps a perfect track of every transaction while also ensuring the safety and security of your finance.
                             <br><br>   Moreover, our confidence is backed with more than 200M already billed/collected and more than 50M sent to different personal/Business Bank accounts via our integrated payment module. Rest assured Enrollible will take on any challenges that our partners might be facing.

                            </p>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-between align-items-center mb-5">
                    <div class="col-md-6">
                        <div class="product-detail text-start" data-aos="fade-up" data-aos-easing="linear">
                            <span class="tagline brand-color">Enrollment</span>
                            <h2>Group Dashboard</h2>
                            <p>If you are leading a group in your organization, this is the perfect dashboard for you. You will not only be able to view your own details but also that of your group members.
                             <br><br>   Moreover, you can also make necessary updates to the information that you have access to.
                            </p>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="product-img left-img" data-aos="fade-up" data-aos-duration="1000">
                            <img src="../assets/images/enrollibleImage/group-dashboard.png" class="img-fluid" alt="">
                            <div class="p-high">
                                <img src="../assets/images/enrollibleImage/group-high.png" class="img-fluid" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End of Products -->

        <section class="contact-wrap pb-5" id="contact">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-7">
                        <div class="section-title text-center mb-4">
                            <h2><span class="brand-color">Contact</span> <br> Talk to Our Sales &  Marketing Department Team</h2>
                            <p>If you have any queries or problems, you can directly contact our sales and marketing them. We will reach out to you as soon as we can. </p>
                        </div>

                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-md-8">
                        <div class="contact-form" data-aos="fade-up" data-aos-duration="2000">
                            <form action="">
                                <div class="form-group row">
                                    <div class="col-xxl-6 col-xl-6 col-lg col-md-6  mb-4">
                                        <label>First Name</label>
                                        <input type="text"
                                               v-model="first_name"
                                               placeholder="First Name"
                                               @input="$v.first_name.$touch(); removeError();"
                                               :class="{'form-control is-invalid':$v.first_name.$error || formErrors['first_name_message']}"
                                               class="custom-input"
                                               name="" id="">
                                          <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.first_name.required && $v.first_name.$error">
                                            Field is required
                                          </div>
                                          <div class="form-group__message  ml-3 mt-2" v-if="$v.first_name.required && $v.first_name.$error">
                                            Please enter valid first name
                                          </div>
                                        <div class="error_message" v-if="formErrorFlag == true">{{formErrors['first_name_message']}}</div>
                                    </div>
                                    <div class="col-xxl-6 col-xl-6 col-lg col-md-6  mb-4">
                                        <label>Last Name</label>
                                        <input type="text"
                                               v-model="last_name"
                                               placeholder="Last Name"
                                               @input="$v.last_name.$touch(); removeError();"
                                               v-bind:class="{'form-control is-invalid':$v.last_name.$error || formErrors['last_name_message']}"
                                               class="custom-input"
                                               name=""
                                               id="">
                                        <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.last_name.required && $v.last_name.$error">
                                          Field is required
                                        </div>
                                        <div class="form-group__message  ml-3 mt-2" v-if="$v.last_name.required && $v.last_name.$error">
                                          Please enter valid last name
                                        </div>
                                        <div class="error_message" v-if="formErrorFlag == true">{{formErrors['last_name_message']}}</div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-xxl-6 col-xl-6 col-lg col-md-6  mb-4">
                                        <label>Phone</label>
                                        <input
                                            type="text"
                                            v-model="phone"
                                            placeholder="Phone"
                                            @input="$v.phone.$touch(); removeError();"
                                            :class="{'form-control is-invalid':$v.phone.$error || formErrors['phone_message']}"
                                            class="custom-input"
                                            name=""
                                            maxlength="10"
                                            id="">
                                        <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.phone.required && $v.phone.$error">
                                          Field is required
                                        </div>
                                        <div class="form-group__message text-left ml-3 mt-2" v-if="$v.phone.required && $v.phone.$invalid">
                                          Invalid phone number
                                        </div>
                                        <div class="error_message" v-if="formErrorFlag == true">{{formErrors['phone_message']}}</div>
                                    </div>
                                    <div class="col-xxl-6 col-xl-6 col-lg col-md-6  mb-4">
                                        <label>Email</label>
                                        <input
                                            type="email"
                                            v-model="email"
                                            placeholder="Email"
                                            class="custom-input"
                                            @input="$v.email.$touch(); removeError();"
                                            :class="{'form-control is-invalid':$v.email.$error || formErrors['email_message']}"
                                            name=""
                                            id="">
                                        <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.email.required && $v.email.$error">
                                          Field is required
                                        </div>
                                        <div class="form-group__message text-left ml-3 mt-2" v-if="$v.email.required && $v.email.$invalid">
                                          Email is not in correct format
                                        </div>
                                        <div class="error_message" v-if="formErrorFlag == true">{{formErrors['email_message']}}</div>
                                    </div>
                                </div>
                                <div class="form-group mb-4">
                                    <label>Message</label>
                                    <textarea name=""
                                              v-model="message"
                                              rows="4"
                                              class="custom-input"
                                              @input="$v.message.$touch(); removeError();"
                                              :class="{'form-control is-invalid':$v.message.$error || formErrors['message_message']}"
                                              placeholder="How can we help you?">
                                    </textarea>
                                        <div class="error_message" v-if="formErrorFlag == true">{{formErrors['message_message']}}</div>
                                </div>
                                <div class="form-group mb-4" id="alert" v-if="showDismissibleAlert==true">
                                    <b-alert variant="success" show>
                                      <p style="color:green">Message has been sent to the admin </p>
                                      <span class="message-close-btn" @click="showDismissibleAlert=false">
                                        <i class="fa fa-times" aria-hidden="true"></i>
                                      </span>
<!--                                      <button @click="showDismissibleAlert=false"
                                              style="cursor:pointer; width:10rem;   border-radius:25px; margin-right:;">
                                        close
                                      </button>-->
                                    </b-alert>
                                </div>
                                <div class="form-group text-center">
                                    <button class="contact-btn"  @click.prevent="sendUserDetail" >Send Message</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div class="sitefooter">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-2 col-sm-6 col-6">
                        <div class="logo mb-3">
                            <img src="../assets/images/enrollibleImage/logo.svg" class="img-fluid" alt="">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="foot-info">
                            <ul>
                                <li><i class="fas fa-phone"></i> 1-888-891-2399</li>
                                <li><i class="fas fa-envelope"></i> info@enrollible.com</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>





        <div class="copyright">
            <div class="container">
                <div class="row">
                    <div class="col-12 border-top">
                        <p>Copyright &copy; 2022 | <span class="brand-color">Enrollible</span></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AOS from 'aos'
import 'aos/dist/aos.css'
import {required, email} from "vuelidate/lib/validators";
import { Carousel3d, Slide } from 'vue-carousel-3d';
import axios from 'axios';
import {checkValidPhoneNumber, validName} from "../utils/validators";
export default {

    name:"NewHome",
    components: {
        Carousel3d,
        Slide
    },
    data:function(){
        return{
            first_name:'',
            last_name:'',
            email:'',
            message:'',
            phone:'',
            formErrorFlag:false,
            formErrors:[],
            showDismissibleAlert:false,
        }
    },
    validations: {
      first_name: {
        required,
        validName
      },
      last_name: {
        required,
        validName
      },
      phone: {
        required,
        checkValidPhoneNumber
      },
      email: {
        required,
        email
      },
      message: {
        required
      }
    },
    mounted() {
        AOS.init();
    let app = this;
    app.tempId = window.localStorage.getItem('tempId');
    app.eprocess = window.localStorage.getItem('eprocess');
    app.open_type = window.localStorage.getItem('open_type');
    app.referral = window.localStorage.getItem('referral');
  },
    methods: {
        sendUserDetail: function () {
          if (this.$v.$invalid) {
            this.$v.$touch();
            return false
          } else {
            let app = this;
            let data = {
              first_name: app.first_name,
              last_name: app.last_name,
              email: app.email,
              phone: app.phone,
              message: app.message,
              data: {}
            }
            app.formErrorFlag = false;
            app.formErrors = [];
            axios.post(process.env.VUE_APP_API_BASE + '/send-user-detail', data)
                .then(function (response) {
                  if (response.data.status == 'success') {
                   // let item = response.data.data.result;
                     app.first_name = '';
                     app.last_name =  '';
                     app.email = '';
                     app.phone = '';
                     app.message = '';
                     app.showDismissibleAlert = true;
                     app.$v.$reset();
                  }
                })
                .catch(function (error) {
                  if (error.response.data.status == 'error') {
                    error.response.data.data.forEach(function (item) {
                      app.formErrorFlag = true;
                      app.formErrors[item.target_element + '_message'] = item.error_message;
                    });
                    console.log(error);
                  }
                });
          }
        },
        removeError () {
          let app = this;
          app.formErrors = []
        }
    },

}
</script>
<style scoped>
@import '../assets/css/responsive.css';
@import '../assets/css/main.css';
@import '../assets/css/slider/masterslider.css';
@import '../assets/css/slider/ms-caro3d.css';
.error_message{
    color:red;
    text-align: left;
}
#alert{

}

.banner-text h1{
  color: #071c4d;
  font-weight: 800;
  font-size: 35px;
  line-height: 50px;
  margin-bottom: 1.3rem;
  margin-top: 40px;
}
button {
  background-color: #161c2d;
}
.banner-text p, .features .section-title p, .about-text-block p, .section-title p{
  color: #575757;
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
}
.message-close-btn {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}
</style>
