<template>
  <section class="page-wrap">
    <div class="banner-wrap">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="breadcrumb">
              <ul>
                <li><a href="#" @click.prevent="homePage">Home</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xxl-5 col-xl-5 col-lg-5 col-12 mb-5">
            <div class="caption">
              <div class="caption_container">
                <div class="caption_boxes" v-if="(agentCode == mbgAgentCode) || isMbgUser">
                  <h1><br> Let's <strong>Get Started</strong></h1>
                  <div class="links">
                    <ul style="list-style:none;">
                      <li v-if="portalPop" :class="{'mbg-btn-sign-up': agentCode == mbgAgentCode || isMbgUser}">
                        <router-link to="#"  class="brand-btn"  @click.native="routeMbgLanding" v-if="agentCode == mbgAgentCode || isMbgUser">Sign Up Today</router-link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="caption_boxes" v-else>
                  <h1><span>Welcome to the Benefit Store</span><br>Let's Get Started...</h1>
                  <div class="links">
                    <ul style="list-style:none;">
                      <li v-if="portalPop">
                        <router-link to="#" @click.native="showPop" class="brand-btn">Start Enrollment</router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <!-- group popup -->
            <div  v-show="visible">
              <div class="card-block">
                <form action="">
                  <div class="col-12 mb-4">
                    <a href="javascript:void(0);" class="dark-btn text-center w-100" @click="nextPageDefault">Start New Member Enrollment</a>
                    <br>
                    <br>
                    <h3> OR </h3>
                    <br>
                    <label for="">Choose an option to enroll a new member:</label>
                    <select name=""
                            class="custom-input "
                            @click="getSubGroupList()"
                            v-model="group_id"
                            id="group_id">
                      <option v-for="item in groupData" :value="item.gid" v-bind:key="item.gid">{{item.gname}}</option>
                    </select>
                    <p style="color:red;" v-if="platformError['error'] == true">{{groupErr['message']}}</p>
                  </div>
                  <br>
                  <div class="sub-group-container" v-if="subGroupList.length !== 0">
                    <label for="">Choose sub group:</label>
                    <select name=""
                            class="custom-input"
                            v-model="subGroupId" id="sub_group_id">
                      <option v-for="(item, index) in subGroupList" :value="item.sub_group_id" v-bind:key="index">{{item.name}}</option>
                    </select>
                  </div>
                  <div class="enroll-btn agent-btn row">
                    <div class="col-xxl-6 col-6">
                      <a href="javascript:void(0);" class="dark-btn mb-3" v-on:click="[visible = false , platformPopup = true];emptyExistingInfo();">Back</a>
                    </div>
                    <br>
                    <div class="col-xxl-6 col-6">
                      <a href="javascript:void(0);" @click="nextPage"  class="brand-btn  mb-3">Submit</a>
                    </div>

                  </div>
                </form>
              </div>
            </div>

            <!--platform popup-->
            <div  v-show="platformPopup" class="popups">
              <div class="card-block" >
                <form action="">
                  <div class="col-12 mb-4">
                    <label for="">Choose Enrollment Portal </label>
<!--                    <select name="" class="custom-input" v-model="platformId" id="platformId">
                      <option v-for="item in platforms" :value="item.platform_group_id" v-bind:key="item.platform_group_id">{{item.platform_name}}</option>
                    </select>-->
                    <b-form-select name="" class="custom-input"
                                   :options="portalList"
                                   @input="preservePortalInfo(portal)"
                                   v-model="portal" id="platformId">
                    </b-form-select>
                    <p style="color:red;" v-if="platformError['error'] == true">{{platformError['message']}}</p>
                  </div>
                  <div class="enroll-btn agent-btn row">
                    <div class="col-6">
                      <a href="javascript:void(0);" class="dark-btn mb-3" v-on:click="[platformPopup = false, portalPop = true]">Back</a>
                    </div>
                    <div class="col-6">
                      <a href="javascript:void(0);" @click.prevent="choosePortal()"  class="brand-btn  mb-3">Submit</a>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div>
            </div>
          </div>
          <div class="col-xxl-7 col-xl-7 col-lg-7 col-12">
            <div class="text-center">
              <img :src="mbgAgentCode ==  agentCode || isMbgUser ? mbgBannerImage: bannerImage" alt="banner-image" class="img-fluid">

            </div>
          </div>
        </div>
      </div>
    </div>
  </section>



</template>

<script>
import axios from 'axios';
export default {
  name: "AgentHome",
  data: function () {
    return {
      groupData: [],
      agent_id: '',
      group_id: '',
      visible: false,
      groupErr: [],
      platforms: [],
      platformError: [],
      // platformId: '',
      portalPop: true,
      platformPopup: false,
      agent_name: '',
      bannerImage: require(process.env.VUE_APP_BANNER_iMAGE),
      mbgBannerImage: require('../assets/images/mbg-new.png'),
      subGroupList: [],
      subGroupId: null,
      existingGroupId: null,
      portal: {},
      portalList: [],
      agentCode: null,
      isMbgUser: false,
      mbgAgentCode: process.env.VUE_APP_MBG_AGENT_CODE
    }
  },
  mounted() {
    let app = this;
    app.agent_id = window.localStorage.getItem('agent_id');
    app.agentCode = window.localStorage.getItem('agent_code');
    app.isMbgUser = JSON.parse(window.localStorage.getItem('isMbgUser'));
    app.agent_name = app.$store.state.agent.agent_fname + ' ' + app.$store.state.agent.agent_lname;
    app.getGroups();
    app.getPlatforms()
  },
  methods: {
    getGroups: function () {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE + '/get-agent-groups', {
        params: {
          agent_id: app.agent_id,
        }
      })
          .then(function (response) {
            console.log(response.data.data);
            if (response.data.status == 'success') {
              app.groupData = []
              app.groupData = response.data.data;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    getPlatforms: function () {
      let app = this;
      app.platforms = [];
      axios.get(process.env.VUE_APP_API_BASE + '/get-enrollment-platforms', {
        params: {
          agent_id: app.agent_id,
        }
      })
          .then(function (response) {
            console.log(response.data.data);
            if (response.data.status == 'success') {
              app.portalList = []
              app.platforms = response.data.data;
              app.platforms.forEach(element => {
                let option = {
                  value: element,
                  text: element.platform_name
                }
                app.portalList.push(option)
              })
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    choosePortal: function () {
      let app = this;
      console.log('platforms' + app.platformId);
      app.platformError = [];
      if (app.portal.platform_group_id) {
        window.localStorage.setItem('portal_id', this.portal.platform_group_id);
        app.platformPopup = false;
        app.visible = true;
        app.getGroups();
      } else {
        app.platformError['error'] = true;
        app.platformError['message'] = "Please choose a platform";
      }
    },
    nextPage: function () {
      this.platformPopup = false;
      this.visible = true;
      this.groupErr = [];
      if (this.group_id == '') {
        this.groupErr['error'] = true;
        this.groupErr['message'] = "Please choose a group";
      } else {
        window.localStorage.setItem('group_id', this.group_id);
        window.localStorage.setItem('enroll_type', 'new-group-member');
        this.$bus.$emit('getMenuList');
        this.$router.push('/home');
      }
    },
    nextPageDefault: function () {
      this.visible = false;
      this.platformPopup = false;
      window.localStorage.setItem('group_id', this.portal.platform_group_id);
      this.$bus.$emit('getMenuList');
      this.$router.push('/home');
    },
    reloadPage: function (pid) {
      this.getPlanDetails();
      this.getPlanPricing();
      this.$router.push({name: 'plan', params: {id: pid}});
    },
    showPop() {
      this.portalPop = false;
      this.platformPopup = true;
      this.getPlatforms();
    },
    closePop() {
      this.visible = false;
    },
    closePopPlatforms() {
      this.platformPopup = false;
    },
    closeportalPop() {
      this.portalPop = false;
    },
    getSubGroupList () {
      let app = this;
      if (app.group_id) {
       if (app.group_id !== app.existingGroupId) {
        // let agent_id = app.$route.params.id;
        axios.get(process.env.VUE_APP_API_BASE + '/get-sub-group', {
          params: {
            group_id: app.group_id,
          }
        })
            .then(function (response) {
              if (response.status == 204) {
                app.subGroupList = []
                window.localStorage.removeItem('subGroupId')
              }
              if (response.data.status == 'success') {
                app.subGroupList = response.data.data
              }
            })
            .catch(function (error) {
              console.log(error);
            });
         app.existingGroupId = app.group_id
        }
      }
    },
    emptyExistingInfo () {
      let app = this;
      app.groupData = [],
      app.subGroupList = []
      app.group_id = null
      app.subGroupId = null
      app.existingGroupId = null
      window.localStorage.removeItem('subGroupId')
      app.getGroups ();
    },
    preservePortalInfo(portalInfo) {
      window.localStorage.setItem('portal_code', portalInfo.platform_code);
      window.localStorage.setItem('portal_name', portalInfo.platform_name);
    },
    homePage: function(){
      if (this.open_type !== null && typeof(this.open_type) !== 'undefined') {
        if (this.referral !== null && typeof(this.referral) !== 'undefined') {
          this.$router.push('/referrals?rid='+this.referral);
        } else {
          this.$router.push('/home');
        }
      } else {
        if (this.eprocess === 'rep') {
          this.$router.push('/agent-home');
        } else if ((this.eprocess === 'applicant' && this.openType === 'agent') || (this.eprocess === 'applicant' && this.openType === 'group')) {
          this.$router.push('/');
        } else if (this.eprocess === 'applicant') {
          this.$router.push('/member-home');
        } else if (this.eprocess === 'group') {
          this.$router.push('/group-home');
        }
      }
    },
    routeMbgLanding () {
      window.localStorage.setItem('portal_code', 'MBG3334');
      window.localStorage.setItem('portal_name', 'MBG Plans');
      window.localStorage.setItem('portal_id', '3334');
      window.localStorage.setItem('group_id', '3334');
      this.$bus.$emit('getMenuList');
      this.$router.push('/home');
    }
  },
  watch: {
    group_id: function () {
      if (this.group_id) {
        this.groupInvaild = false;
      }
    },
    platformId: function () {
      if (this.platformId) {
        this.platformError['error'] = false;
      }
    },
    subGroupId: function () {
      if(this.subGroupId) {
        localStorage.setItem('subGroupId', this.subGroupId)
      }
    },
    'portal.platform_group_id': function () {
      if (this.portal.platform_group_id) {
        this.platformError['error'] = false;
      }
    }
  },
  listener: {
    renderMbguser () {
      let app = this
      app.isMbgUser = Boolean(window.localStorage.getItem('isMbgUser'));
    }
  }
}
</script>

<style lang="scss">
.sub-group-container {
  position: relative;
  top: -28px;
}
</style>
