<template>
  <div id="app">
    <loader v-if="showLoading"></loader>
    <router-view/>
  </div>
</template>

<script>
import loader from './components/LoaderGlobal.vue'
import { mapState } from 'vuex';
export default {
  name: 'App',
  components: {
    loader
  },
  data(){
    return{
      defaultHeader: true,
      defaultFooter: true,
      mbgGroupCode: process.env.VUE_APP_MBG_GROUP_CODE,
      groupCode: '',
      removeBackground: false,
      isMbgUser: false
    }
  },
  mounted () {
    this.groupCode = window.localStorage.getItem('group_code')
    this.isMbgUser = JSON.parse(window.localStorage.getItem('isMbgUser'));
    this.renderFavIcon();
    this.checkMbgBackground();
  },
  methods: {
    renderFavIcon() {
      if (process.env.VUE_APP_SITE_NAME == 'optionPlus') {
        let favicon = document.getElementById("favicon");
        favicon.href = 'options-plus-fav.jpg'
        document.title = `Easiest Enrollment System-${process.env.VUE_APP_SITE_NAME}`
      } else {
        let favicon = document.getElementById("favicon");
        favicon.href = 'favicon.png'
        document.title = `Easiest Enrollment System-${process.env.VUE_APP_SITE_NAME}`
      }
    },
    checkMbgBackground() {
      if (this.mbgGroupCode === this.groupCode || this.isMbgUser) {
        document.body.style.background = 'none';
        document.body.style.backgroundColor = '#FFFFFF';
        document.body.style.setProperty("--brand-color", "#1848a0");
        document.body.style.setProperty("--brand-btn", "#1848a0");
      } else {
        document.body.style.backgroundColor = '#f3f8fa';
        document.body.style.setProperty("--brand-color", "#11809c");
        document.body.style.setProperty("--brand-btn", "#11809c");
      }
    }
  },
  watch: {
    "$route.params": {
      handler() {
        if (
            this.$route.name == "error"
        ) {
          this.defaultHeader = false;
          this.defaultFooter = false;
        } else {
          this.defaultHeader = true;
          this.defaultFooter = true;
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState({
      showLoading: state => state.showLoading
    })
  },
  listener: {
    checkColor () {
      let app = this
      this.groupCode = window.localStorage.getItem('group_code')
      app.isMbgUser = Boolean(window.localStorage.getItem('isMbgUser'));
      app.checkMbgBackground();
    }
  }
}
</script>

<style>
@import url('assets/fonts/stylesheet.css');
@import url('assets/css/common.css');
#app {
  font-family: 'Raleway' !important;
}
body{
  background-color: #f3f8fa;
  background: url(./assets/body-bg.png) no-repeat;
  position: relative;
  content: '';
  width: 100%;
  height: 100%;
  background-size: inherit;
  min-height: 100vh;
  background-position: right top;
  z-index: -1;
  background-attachment: fixed;
}
a, a:hover{
  color: inherit;
  text-decoration: none !important;
}
</style>

<style lang="scss" >
//#app {
//    font-family: Avenir, Helvetica, Arial, sans-serif;
//    -webkit-font-smoothing: antialiased;
//    -moz-osx-font-smoothing: grayscale;
//    text-align: center;
//    color: #2c3e50;
//}
// @import "css/common.css";
// @import "css/index.css";
/*@import "css/main.css";*/
//@import "css/plan_desc.css";
// @import "scss/main";
// @import "./assets/css/main.css";
// @import "assets/scss/main.scss";

</style>
