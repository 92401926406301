<template>
  <section class="content_container">
    <section class="innerpage-wrap">
      <div class="container">
        <div class="row">
          <div class="col-12"></div>
<!--          <plan-detail></plan-detail>-->
        </div>
      </div>
    </section>
    <!--    </div>-->
  </section>
</template>

<script>
import axios from "axios";
// import PlanDetail from "../components/plan/PlanDetail";
export default {
  name: "NewGroupPlan",
/*  components: {
    'plan-detail' : PlanDetail
  },*/
  data: function () {
    return {
      gname: '',
      gcode: '',
      username: '',
      usertype: '',
      eprocess: '',
      isCurrentMemberAvailable: false,
      groupId: '',
      planId: ''
    }
  },
  mounted() {
    let app = this;
    app.groupId = app.$route.params.encryptedGroupId;
    app.planId = app.$route.params.encryptedPlanId;
    window.localStorage.setItem('planId', app.planId);
    app.getNewGroupInfo()
  },
  methods: {
    getNewGroupInfo () {
      let app = this
      axios.get(process.env.VUE_APP_API_BASE + "/new/get-group-info", {
        params: {
          group_id: app.groupId
        }
      })
          .then(function (response) {
            if (response.data.status == "success") {
              let groupInfo = response.data.data;
              window.localStorage.setItem('agent_id', groupInfo.agent_id);
              window.localStorage.setItem('AGENT_ID', groupInfo.agent_id);
              window.localStorage.setItem('group_id', app.groupId);
              window.localStorage.setItem('portal_id', groupInfo.portal_id);
              window.localStorage.setItem('eprocess', groupInfo.eprocess);
              window.localStorage.setItem('enroll_type', 'new-group-member');
              window.localStorage.setItem('siteRedirection', 'new-group');
              app.setRenderInfo();
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    setRenderInfo () {
      let app = this
      app.$router.push(`/plan/${app.planId}/new-group`)
      app.$router.go();
    }
  }
}
</script>

<style >
@import url('../assets/css/plan_desc.css');
</style>
